import ScopeOneScopeThreeEmissionModule from "@ems/containers/ScopeOneAndThreeEmission/ScopeOneAndThreeEmission.module";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import {
  IEmissionChartDTO,
  IEmissionDTO,
  IEmissionPostDTO,
  IYearEmissionDTO,
  IEmissionWorldMapDTO,
} from "@/models/Emission";
import { ErrorResponse } from "@/services/axios/error";
import { EmissionAPI } from "@/services/emission";
import store from "@/store";

import {
  convertDataWorldMap,
  covertDataTarget,
  parseDataHistory,
  totalHistory,
} from "@/utils/helpers/convertDataChart";
import { getYear } from "@/utils/helpers/convertDateTime";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import SettingBaseLineModule from "@ems/containers/SettingInformation/SettingBaseLine.module";

const name = "Emissions";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class EmissionModule extends VuexModule {
  private error = {} as ErrorResponse;
  private errorEmissionYear = {} as ErrorResponse;
  private errorEmission = {} as ErrorResponse;
  private isLoadingEmission = false as boolean;
  private isLoadingEmissionChart = false as boolean;

  private dataEmission = {} as IEmissionDTO;
  private dataEmissionChart = [] as IEmissionChartDTO[][];
  private dataEmissionYear = {} as IEmissionDTO;
  private currentYear = new Date().getFullYear() as string | number | Date;
  private origin = 0 as number;
  private dataMaxPercentChart = 100 as number;
  private dataYearEmission = [] as IYearEmissionDTO[];
  private target = 0 as number;

  private worldMapEmission = [] as any;
  private isLoadingWordMapEmission = false;
  private errorWorldMapEmission = {} as ErrorResponse;

  private isLoadingGetEmissionHistory = false;

  private ScopeOneHistory = [] as any;
  private ScopeTwoHistory = [] as any;
  private ScopeThreeHistory = [] as any;
  private TotalScopeHistory = [] as any;

  get ScopeOneHistoryGetter() {
    return this.ScopeOneHistory;
  }
  get ScopeTwoHistoryGetter() {
    return this.ScopeTwoHistory;
  }
  get ScopeThreeHistoryGetter() {
    return this.ScopeThreeHistory;
  }
  get TotalScopeHistoryGetter() {
    return this.TotalScopeHistory;
  }

  get isLoadingGetEmissionHistoryGetter() {
    return this.isLoadingGetEmissionHistory;
  }
  @Mutation
  loadingEmission(isLoading = false as boolean) {
    this.isLoadingEmission = isLoading;
  }

  get dataEmissionYearGetter() {
    return this.dataEmissionYear;
  }
  get loadingEmissionGetter() {
    return this.isLoadingEmission;
  }

  get emissionOriginGetter() {
    return this.origin;
  }

  @Mutation
  setEmissionYearSuccess(dataEmissionYear: IEmissionDTO) {
    this.dataEmissionYear = dataEmissionYear;
    this.origin = dataEmissionYear.Origin;
    this.isLoadingEmission = false;
  }

  @Action
  async fetchEmissionYear(payload: number | string | Date) {
    this.loadingEmission(true);
    const serviceEmissionAPI = new EmissionAPI();
    const { data, error } = await serviceEmissionAPI.getEmissionYear(payload);
    if (error) {
      this.setEmissionError(error);
    } else {
      this.setEmissionYearSuccess(data);
    }
  }

  get dataEmissionGetter() {
    return this.dataEmission;
  }
  get dataTargetGetter() {
    return this.target;
  }
  @Mutation
  setEmissionError(error: ErrorResponse) {
    this.errorEmission = error;
  }

  @Mutation
  setEmissionSuccess(dataEmission: IEmissionDTO) {
    dataEmission.Factor = dataEmission.Factor * 100;
    this.dataEmission = dataEmission;
    this.origin = dataEmission.Origin;
  }

  @Mutation
  setTargetSuccess(target: number) {
    this.target = target;
  }
  get dataEmissionChartGetter() {
    return this.dataEmissionChart;
  }
  @Mutation
  setEmissionChartTargetSuccess(dataEmission: IEmissionChartDTO[][]) {
    this.dataEmissionChart = dataEmission;
  }
  @Action
  async fetchEmission(payload: any) {
    const serviceEmissionAPI = new EmissionAPI();
    const { data, error } = await serviceEmissionAPI.getEmission();
    if (error) {
      this.setEmissionError(error);
    } else {
      this.setEmissionSuccess(data);
      const convertData = covertDataTarget(data?.Target, payload) as any;
      this.setEmissionChartTargetSuccess(convertData);
    }
  }

  @Mutation
  setCreateEmissionError(error: ErrorResponse) {
    ElMessage.error("Submit baseline failed.");
    this.errorEmission = error;
  }

  @Mutation
  setCreateEmissionSuccess() {
    ElMessage.success("Submit Baseline success.");
    this.errorEmission = {} as ErrorResponse;
  }
  @Action
  async createEmission(payload: IEmissionPostDTO) {
    const serviceEmissionAPI = new EmissionAPI();
    const { data, error } = await serviceEmissionAPI.createEmissionYear(
      payload
    );
    if (error) {
      this.setCreateEmissionError(error);
    } else {
      this.setCreateEmissionSuccess();
    }
  }

  @Mutation
  loadingYearsEmission(isLoading = false as boolean) {
    this.isLoadingEmission = isLoading;
  }

  get dataYearsEmissionGetter() {
    return this.dataYearEmission;
  }
  get loadingYearEmissionGetter() {
    return this.isLoadingEmission;
  }

  @Mutation
  setYearsEmissionSuccess(dataYearEmission: IYearEmissionDTO[]) {
    dataYearEmission.sort(function (a, b) {
      return a.Value - b.Value;
    });
    this.dataYearEmission = dataYearEmission;
    this.isLoadingEmission = false;
  }

  @Action
  async fetchYearsEmission() {
    this.loadingEmission(true);
    const serviceEmissionAPI = new EmissionAPI();
    const { data, error } = await serviceEmissionAPI.getYearsEmission();
    if (error) {
      this.setEmissionError(error);
    } else {
      this.setYearsEmissionSuccess(data);
    }
  }
  @Mutation
  setLoadingWorldMapEmission(isLoading = false as boolean) {
    this.isLoadingWordMapEmission = isLoading;
  }

  @Mutation
  setWorldMapEmissionSuccess(data: any) {
    this.worldMapEmission.ScopeOneEmissions = convertDataWorldMap(
      data.ScopeOneEmissions,
      "id",
      "value"
    );
    this.worldMapEmission.ScopeTwoEmissions = convertDataWorldMap(
      data.ScopeTwoEmissions,
      "id",
      "value"
    );
    this.worldMapEmission.ScopeThreeEmissions = convertDataWorldMap(
      data.ScopeThreeEmissions,
      "id",
      "value"
    );
    this.isLoadingWordMapEmission = false;
  }

  @Mutation
  setWorldMapEmissionError(error: ErrorResponse) {
    this.errorWorldMapEmission = error;
    this.isLoadingWordMapEmission = false;
  }

  @Action
  async getWorldMapEmission(payload: IEmissionWorldMapDTO) {
    this.setLoadingWorldMapEmission(true);
    const serviceEmissionAPI = new EmissionAPI();
    const { data, error } = await serviceEmissionAPI.getWorldMapEmission(
      payload
    );
    if (error) {
      this.setWorldMapEmissionError(error);
    } else {
      this.setWorldMapEmissionSuccess(data);
    }
  }

  get worldMapEmissionGetter() {
    return this.worldMapEmission;
  }

  get isLoadingWordMapEmissionGetter() {
    return this.isLoadingWordMapEmission;
  }

  @Action
  async getEmissionHistory(payload: any) {
    this.loadingGetEmissionHistory(true);
    return Promise.all([
      ScopeOneScopeThreeEmissionModule.getScopeOneHistoryAction(payload),
      ScopeTwoEmissionModule.getScopeTwoHistoryAction(payload),
      ScopeOneScopeThreeEmissionModule.getScopeThreeHistoryAction(payload),
      SettingBaseLineModule.getGHGTargetsAction(),
      SettingBaseLineModule.getGHGTargetsLongTermAction(),
      SettingBaseLineModule.getGHGTargetsShortTermAction(),
    ])
      .then((values: any) => {
        this.setEmissionHistorySuccess(values);
        this.loadingGetEmissionHistory(false);
      })
      .catch((error: any) => {
        return false;
      });
  }
  @Mutation
  loadingGetEmissionHistory(isLoading: boolean) {
    this.isLoadingGetEmissionHistory = isLoading;
  }
  @Mutation
  setEmissionHistorySuccess(data: any) {
    this.ScopeOneHistory = parseDataHistory(data[0], data[3]);
    this.ScopeTwoHistory = parseDataHistory(data[1], data[3]);
    this.ScopeThreeHistory = parseDataHistory(data[2], data[3]);
    this.TotalScopeHistory = totalHistory(data[0], data[1], data[2], data[3]);
  }
}

export default getModule(EmissionModule);
